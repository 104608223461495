.background-image {
    background-image: url("assets/images/other-images/blank.jpg"); /* Set the image path */
    background-size: cover; 
    background-repeat: no-repeat;
    width: 550px; /* Optional: Set width */
    height: 200px; /* Optional: Set height */
  }

  .inquiry ul {
    overflow-y: scroll;
  }
  .inquiry li {
    padding: 5px;
    margin: 1px;
    cursor: pointer;
    border-radius: 3%;
    border: 1px solid rgb(208, 208, 208);
  }

  .inquiry .selected {
    background-color: rgb(241, 241, 241);
  }

  .inquiry li div {
    font-weight: bold;
  }


  
  .inquiry .control,
  .inquiry .message {
    padding: 5px;
    margin: 2px;
    border-radius: 3%;
    border: 1px solid rgb(208, 208, 208);
  }

  .inquiry .message {
    height: 100%;
  }

  .badge {
    font-size: 14px;
    padding: 6px 12px;
  }

.personalized-formula div.table-responsive {height: 500px !important;}
.personalized-formula table thead tr th { background-color: #fff; }
.personalized-formula table tr th { border-bottom: 2px solid rgb(33, 37, 41); }
.personalized-formula table th { position: sticky; top: 0; }

.personalized-formula div.table-responsive::-webkit-scrollbar {
  width: 5px;
}
.personalized-formula div.table-responsive::-webkit-scrollbar-track {
  background: transparent;
}
.personalized-formula div.table-responsive::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}